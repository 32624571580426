<template>
  <div class="quick_entry">
    <dec-block-title>快捷入口</dec-block-title>
    <div class="quick_button_box">
      <el-button plain @click="toPage('/')">
        <div>
          <i class="icon iconfont icondiqiu"></i>监管地图
        </div>
        <i class="el-icon-right el-icon--right"></i>
      </el-button>
      <el-button plain @click="toPage('/AlarmDetector')">
        <div>
          <i class="icon iconfont iconjiance"></i>报警管理-监测
        </div>
        <i class="el-icon-right el-icon--right"></i>
      </el-button>
      <el-button plain @click="toPage('/Intime')">
        <div>
          <i class="icon iconfont iconshishi"></i>实时管理
        </div>
        <i class="el-icon-right el-icon--right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import decBlockTitle from '../decorations/blockTitle2'
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: 'quickEntry',
  components: {
    decBlockTitle
  },
  data() {
    return {
      option: {}
    }
  },
  methods: {
    /**
     * @description 跳转页面
     */
    toPage(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style lang="scss">
.quick_entry {
  .quick_button_box {
    padding: 24px 15px;

    button,
    button:hover,
    button:focus{
      margin: 0 0 16px;
      background-color: transparent;
      border-color: #41D0D2;
      border-radius: 4px;
      color: #41D0D2;
      width: 192px;
      text-align: left;
      height: 40px;
      font-size: 14px;
      padding: 0 14px;
      line-height: 40px;

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          display: flex;
          align-items: center;

          .iconfont {
            font-size: 20px;
            margin-right: 10px;
          }
        }

        >.el-icon-right {
          font-size: 20px;
        }
      }
    }

    button:hover {
      opacity: 0.6;
    }
  }
}
</style>
